import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';
import { SharedModule } from '../shared.module';
import { ReactiveFormsModule } from '@angular/forms';

import { BlogRoutingModule } from './blog-routing.module';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { FeaturedListComponent } from './featured-list/featured-list.component';
import { FrontPageListComponent } from './front-page-list/front-page-list.component';
import { CategoriesComponent } from './categories/categories.component';
import { SearchComponent } from './search/search.component';
import { CommentsComponent } from './comments/comments.component';
import { SubcommentsComponent } from './subcomments/subcomments.component';


@NgModule({
  declarations: [HomeComponent, BlogComponent, FeaturedListComponent, FrontPageListComponent, CategoriesComponent, SearchComponent, CommentsComponent, SubcommentsComponent],
  imports: [
    CommonModule,
    BlogRoutingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ShareButtonsModule,
    ShareIconsModule,
    SharedModule
  ],
  exports:[
    FrontPageListComponent
  ]
})
export class BlogModule { }
