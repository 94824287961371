<div class="container">
    <div class="row">
        <div class="col-md-6">
            <p>
                On my <a href="https://www.youtube.com/@amyswares" target="_blank">YouTube channel</a> I post at least
                four videos per week with various different paper
                crafting related subjects and tutorials. I'm also active on <a
                    href="http://www.instagram.com/amybeth928" target="_blank">Instagram</a> and various card making
                groups on Facebook. If you enjoy my vids and tutorials and want to say thanks and help support my
                channel, there are
                some options to do so here. I beg of you, by no means feel obligated or pressured to do this. Many of
                you already
                do this and I wanted to provide you with as many options as possible that don't keep a large cut for
                themselves (30%
                yeah, I'm lookin' at you YouTube). Also, please know that by watching my vids, following my affiliate
                links, hitting
                the thumbs up, and leaving me your amazing encouraging comments, that you are also helping me immensely.
                So, many
                MANY thanks for all you do, I wouldn't be here without you! Also, if you do opt to leave me a tip,
                please email me
                your full name and home address at <a href="mailto:info@amyswares.com"
                    target="_blank">info@amyswares.com</a> so that I can send you a thank you card! Big Crafty Hugs
                comin' atcha!
            </p>
        </div>
        <div class="col-md-6">
            <div class="row mt-2">
                <div class="col">
                    <a href="https://www.buymeacoffee.com/amyswares" target="_blank"><img
                            src="https://cdn.buymeacoffee.com/buttons/v2/default-violet.png" alt="Buy Me A Coffee"
                            style="height: 60px !important;width: 217px !important;"></a>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <a class="btn btn-lg btn-block btn-secondary" href="https://venmo.com/u/amyswares" target="_blank">VENMO</a>
                </div>
            </div>
        </div>
    </div>
</div>