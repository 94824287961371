<div class="container">
    <div *ngIf="blogs$ | async; let blogs; else loading">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-12">
                <div *ngFor="let blog of blogs;let indexOfElement=index;">
                    <div class="card border-0 mb-4" style="cursor:pointer;" (click)="goToBlog($event,blog.id)">
                        <img [src]="blog.coverPhoto" class="card-img-top" alt="blog.title">
                        <div class="card-body">
                            <h5 class="card-title" [innerHTML]="blog.title"></h5>
                            <p class="card-text" [innerHTML]="blog.introduction"></p>
                        </div>

                    </div>
                    <hr/>
                    <div *ngIf="indexOfElement === blogs.length - 1" class="row">
                        <div class="col-6">
                            <button  class="btn btn-primary" (click)="goToNewerPosts(startTimeFilter$.value)">See Newer Posts</button>                       
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                            <button class="btn btn-primary" (click)="goToOlderPosts(blog.postDate)">See Older Posts</button>
                        </div>
                    </div>
                </div>
                <app-featured-list></app-featured-list>
                <div *ngIf="blogs.length === 0">No blog posts to read!</div>        
            </div>
            <div class="col-lg-3 col-12">
                <div style="width:100%;">
                    <div class="row m-3">
                        <div class="col-12">
                            <a class="btn btn-lg btn-block btn-link" routerLink="/blog/home" style="border-radius:0px;">BLOG</a>
                        </div>
                    </div>
                    <div class="row m-3">
                        <div class="col-12">
                            <a class="btn btn-lg btn-block btn-link"  routerLink="/hub" style="border-radius:0px;">LINKS</a>
                        </div>
                    </div>
                    <div class="row m-3">
                        <div class="col-12">
                            <a class="btn btn-lg btn-block btn-link"  routerLink="/about"  style="border-radius:0px;">ABOUT</a>
                        </div>
                    </div>
                    <div class="row m-3">
                        <div class="col-12">
                            <a class="btn btn-lg btn-block btn-link"  routerLink="/contact" style="border-radius:0px;">CONTACT</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
    
            </div>
            <div class="col-6">
            </div>
        </div>
    </div>
    <ng-template #loading>Loading...</ng-template>
</div>
