import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';
import {Blog} from 'src/app/models/blog.model';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})
export class SearchComponent implements OnInit {
  startTimeFilter$: BehaviorSubject<Date|null>;
  endTimeFilter$: BehaviorSubject<Date|null>;
  arrSearchFilter$: BehaviorSubject<string[]|null>;
  blogs$: Observable<Blog[]>;
  initialSearch: string;
  private sub: any;
  arrSearch: Array<string>;
  searchForm: FormGroup;

  goToBlog(event,blogId){
    this.router.navigateByUrl('blog/entry/' + blogId);
  }
  goToOlderPosts(startTime: any|null){
    console.log(startTime);
    this.endTimeFilter$.next(null);
    this.startTimeFilter$.next(startTime);
  }
  goToNewerPosts(endTime: any|null){
    console.log(endTime);
    this.startTimeFilter$.next(null);
    this.endTimeFilter$.next(endTime);
  }
  getForm(): FormGroup{
    return new FormGroup({
      searchInput: new FormControl()
    })
  }
  updateSearch(){
    if (this.searchForm.get('searchInput').value != "") {
      console.log(this.searchForm.get('searchInput').value.split(" ",10));
      this.arrSearchFilter$.next(this.searchForm.get('searchInput').value.split(" ",10))
      }
  }
  constructor(private afs: AngularFirestore, private router:Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.initialSearch = params['q'];
      this.initialSearch = this.initialSearch.toLowerCase();
      this.arrSearch = this.initialSearch.split(" ", 10);
      this.searchForm = this.getForm();
      this.searchForm.patchValue({searchInput:this.initialSearch})  
      this.startTimeFilter$ = new BehaviorSubject(null);
      this.endTimeFilter$ = new BehaviorSubject(null);
      this.arrSearchFilter$ = new BehaviorSubject(this.arrSearch);
  
      this.blogs$ = combineLatest(
        this.startTimeFilter$,
        this.endTimeFilter$,
        this.arrSearchFilter$
      ).pipe(
        switchMap(([startTime, endTime, searchArray]) => 
          this.afs.collection<Blog>('blogs', ref => {
            let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            let d = new Date();
            query = query.where('postDate','<=',d);
            query = query.where('blogKeywords','array-contains-any',searchArray);
            query = query.orderBy('postDate','desc');
            if (startTime) { query = query.startAfter(startTime)};
            if (endTime) { query = query.endAt(endTime)};
            query = query.limit(5);
            return query;
          }).valueChanges({idField:'id'})
        )
      );
    })

  }
}
