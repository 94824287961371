
<div class="container">
    <div class="row">
        <!-- 1 column spacing -->
        <div class="col-lg-8 offset-lg-2 col-12">
            <div class="row">
                <div class="col d-flex justify-content-center">
                    <img [src]="bBlog?.coverPhoto" class="img-fluid" alt="cover photo">
                </div>
            </div>
            <div class="row mt-2 mb-4">
                <div class="col">
                    <div class="h3 text-primary" style="font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif" [innerHTML]="bBlog?.title"></div>
                </div>
            </div>
            <div class="row mb-3 justify-content-between">
                <div class="col-4">
                    <div class="row">
                        <div class="col">
                            <div class="h6" [innerHTML]="bBlog?.author"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <small class="text-muted" [innerHTML]="pDate + ' in ' + bBlog?.category"></small>
                        </div>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-end">
                </div>
            </div>
            <div class="row">
                <div class="col ck-content" style="font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif" [innerHTML]="bBlog?.blogContent | safe: 'html'">   
                </div>
            </div>
            <hr />
            <div class="row mb-3 justify-content-between">
                <div class="col-6">
                    <div class="row">
                        <div class="col">
                            <div class="h5">FOLLOW ME:</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <a class="btn btn-link" href="http://facebook.com/amyswares" target="_blank" style="color:rgb(179, 179, 179);"><fa-icon [icon]="faFacebookF"></fa-icon></a>
                            <a class="btn btn-link" href="http://instagram.com/amybeth928" target="_blank"style="color:rgb(179, 179, 179);"><fa-icon [icon]="faInstagram"></fa-icon></a>
                            <a class="btn btn-link" href="http://etsy.com/shop/amyswares" target="_blank"style="color:rgb(179, 179, 179);"><fa-icon [icon]="faEtsy"></fa-icon></a>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col">
                            <h5>SHARE THIS:</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" style="color:rgb(179, 179, 179);">
                            <share-buttons 
                            [include]="['facebook','copy','twitter','pinterest','email']"
                            [show]="5"
                            [size]="-4"></share-buttons>    
                        </div>
                    </div>
                </div>
                <app-featured-list></app-featured-list>
            </div>
            <div id="hidden-blog-id" class="d-none" [attr.data-blogid]="blogId"></div>
            <app-comments [blogIdComments]=blogId></app-comments>
        </div>
        <div class="col-lg-2 col-12">
            <div style="width:100%;">
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link" routerLink="/blog/home" style="border-radius:0px;">BLOG</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/hub" style="border-radius:0px;">LINKS</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/about"  style="border-radius:0px;">ABOUT</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/contact" style="border-radius:0px;">CONTACT</a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
