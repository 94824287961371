<div class="m-2">
    <div class="row">
        <div class="col mt-5">
            <h4 class="text-center" style="color:#a0a0a0;">Featured Blogs</h4>
        </div>
    </div>
    <div *ngIf="arrBlogs; let blogs; else loading">
        <div id="featured-carousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div [ngClass]="(i===0)?'carousel-item active':'carousel-item'" *ngFor="let page of [].constructor(pages);let i=index;">
                    <div class="row">
                        <div class="col-4" *ngFor="let b of [].constructor(pagesize);let j=index;">
                            <div *ngIf="blogs[pagesize*i+j]" class="card border-0 mb-0" style="cursor:pointer;"
                                (click)="featuredRedirect(blogs[pagesize*i+j].id)">
                                <img [src]="blogs[pagesize*i+j].coverPhoto" class="card-img-top" alt="blogs[pagesize*i+j].title">
                                <div class="card-body pb-0">
                                    <p class="card-text" [innerHTML]="blogs[pagesize*i+j].title"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#featured-carousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#featured-carousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
    <ng-template #loading>Loading&hellip;</ng-template>
</div>