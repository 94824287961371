import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'Amy\'s Wares';
  constructor(firestore: AngularFirestore, private titleService: Title, private metaService: Meta){
    
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'robots',content:'index, follow'},
      {property: 'og:locale', content:'en_US'},
      {property: 'og:type', content:'article'},
      {property:'site_name', content:'Amy\'s Wares'},
      {property:'article:publisher', content:'https://www.facebook.com/amyswares'}
    ])
  }
}
