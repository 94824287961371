import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  constructor(private metaService: Meta) {
    
  }

  ngOnInit(): void {
    this.metaService.updateTag({name: 'description', content:'Amy\'s Wares is a blog that showcases crafts made by Amy Schrock. She designs and creates handmade cards using various mediums including stamps, copic markers, watercolors, stencils and more...'})
  }

}
