import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent implements OnInit {
  data = {
    name: 'Amy Schrock',
    bio: 'Card Maker',
    image: 'https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/aboutme%2Fsm_aboutme.jpg?alt=media&token=640f8b7d-d7d8-4af9-a51c-b7ae6d7db0ce'
  }
  constructor(private title: Title, private meta: Meta) {
  
   }

  ngOnInit(){
    this.title.setTitle(this.data.name);
    this.meta.addTags([
      {name:'twitter:card',content:'summary'},
      { name: 'og:url', content: '/about'},
      {name: 'og:title', content: this.data.name},
      {name: 'og:description', content: this.data.bio},
      {name: 'og:image', content: this.data.image}
    ])
  }

}
