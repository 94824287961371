import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BlogComment, BlogCommentForm } from 'src/app/models/blogcomment.model';
import { BlogcommentService } from 'src/app/models/blogcomment.service';
import { FormGroup, FormBuilder,Validators, FormControl } from '@angular/forms';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.sass']
})
export class CommentsComponent implements OnInit {
  @Input() blogIdComments: string;
  blogComments$:Observable<BlogComment[]>;
  blogCommentFormGroup: FormGroup;
  blogCommentForm: BlogCommentForm;

  constructor(public bcs: BlogcommentService, private formBuilder: FormBuilder, private afs: AngularFirestore) {
   }

  ngOnInit(): void {
    console.log("constructor blogId: " + this.blogIdComments);
    this.blogComments$ = this.bcs.getBlogComments(this.blogIdComments);
    this.blogCommentFormGroup = new FormGroup({
      'author': new FormControl(null, [Validators.required]),
      'blogId': new FormControl(),
      'content': new FormControl(null, [Validators.required]),
      'commentDate': new FormControl(),
      'approved': new FormControl(),
      'isSubComment': new FormControl()
    })
  }

  async onSubmit(){
    if (this.blogCommentFormGroup.valid){
      const result: BlogCommentForm = Object.assign({}, this.blogCommentFormGroup.value);
      result.approved = false;
      result.isSubComment = false;
      result.commentDate = new Date();
      result.blogId = this.blogIdComments;
      const res = await this.afs.collection('blogComments').add(result).then(() =>{
        document.getElementById("blog-comment-form").classList.add("d-none");
        document.getElementById("blog-comment-alert").classList.remove("d-none");
        document.getElementById("blog-comment-alert").classList.add("alert-success");
        document.getElementById("blog-comment-alert").innerHTML = "Your comment was saved and is awaiting approval.";
      });
    } else {
      alert("Please fill in a name and comment.");
    }
  }
}
