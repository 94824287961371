import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from 'src/app/auth/auth.service';
import {Blog} from 'src/app/models/blog.model';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import {faEtsy} from '@fortawesome/free-brands-svg-icons';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {Title, Meta} from '@angular/platform-browser';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.sass']
})
export class BlogComponent implements OnInit {
  faFacebookF = faFacebookF;
  faEtsy = faEtsy;
  faInstagram = faInstagram;
  blogId: string;
  private blogDoc: AngularFirestoreDocument<Blog>;
  blog: Observable<Blog>;
  bBlog: Blog;
  pDate: string;
  constructor(public asvc: AuthService, private actRoute: ActivatedRoute, private afs: AngularFirestore, private titleService: Title, private metaService: Meta) {
    // asvc: for use with commenting?
    console.log(asvc.user);
   }
   update(blog:Blog){
     this.blogDoc.update(blog);
   }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params =>{
      this.blogId = params.get('id');
      this.afs.doc<Blog>('blogs/' + this.blogId).valueChanges().subscribe(blog => {
        if (blog) {
          if (blog.published) {
            this.bBlog = blog;
            let d = new Date(blog.postDate.seconds * 1000);
            this.pDate = d.toDateString();
            console.log(this.pDate);
            this.titleService.setTitle(blog.title);
            this.metaService.updateTag({ property: 'article:published_time', content: d.toISOString() });
            this.metaService.updateTag({ name: 'description', content: blog.introduction });
            this.metaService.updateTag({ property: 'og:description', content: blog.introduction });
            this.metaService.updateTag({ property: 'og:url', content: 'https://amyswares.com/blog/entry/' + blog.id});
            this.metaService.updateTag({ property: 'og:image', content: 'https://www.amyswares.com/assets/img/aw_small.png'});
            
          }
        } else {
          this.bBlog.blogContent = "Blog entry not found...";
          this.bBlog.title = "No Blog Found"
        }
      });

    })

  }

}
