import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { BlogComment } from 'src/app/models/blogcomment.model';


@Injectable({
  providedIn: 'root'
})
export class BlogcommentService {
  getBlogComments(blogId){
    let bcs$ = this.afs.collection<BlogComment>('blogComments', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('blogId', '==', blogId);
      query = query.where('isSubComment','==',false);
      query = query.where('approved', '==', true);
      query.orderBy('commentDate','asc');
      return query;
    }).valueChanges({idField:'id'});
    return bcs$;
  }

  constructor(private afs: AngularFirestore) { }
}
