import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-favorite-supplies',
  templateUrl: './my-favorite-supplies.component.html',
  styleUrls: ['./my-favorite-supplies.component.sass']
})
export class MyFavoriteSuppliesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
