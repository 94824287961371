<!-- Footer -->
<footer class="footer mt-auto">
    <div class="row mt-5 text-center text-xs-center text-sm-left text-md-left">
        <div class="col-2">
            <a class="nav-link" routerLink="/admin/tools" style="color:#a0a0a0;">Admin</a>
        </div>
        <div class="col-8">
        </div>
        <div class="col-2">
        </div>
    </div>
</footer>
<!-- ./Footer -->