import { NgModule } from '@angular/core';
import { SafePipe } from './models/safe.pipe';

@NgModule({
  declarations: [
    SafePipe
   ],
  imports: [  ],
  exports:[
    SafePipe
  ]
})
export class SharedModule { }
