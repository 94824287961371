import { Component, OnInit } from '@angular/core';
import { Router } from  '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import {faEtsy} from '@fortawesome/free-brands-svg-icons';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  faFacebookF = faFacebookF;
  faEtsy = faEtsy;
  faInstagram = faInstagram;

  constructor(private router: Router, public auth: AngularFireAuth) {

   }

  ngOnInit(): void {
  }
  login(){
    this.router.navigateByUrl('user/login');
  }
  logout(){
    this.auth.signOut()
  }
}
