import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import {ShareIconsModule} from 'ngx-sharebuttons/icons';

// views and components
import { AppComponent } from './app.component';
import { HubComponent } from './hub/hub.component';
import {BlogModule} from './blog/blog.module';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HeaderComponent} from './layout/header/header.component';
import { FooterComponent} from './layout/footer/footer.component';
import { HomeLayoutComponent } from './layout/home-layout/home-layout.component';
import { BlogLayoutComponent } from './layout/blog-layout/blog-layout.component';
import { BlogHeaderComponent } from './layout/blog-header/blog-header.component';
import { MyFavoriteSuppliesComponent } from './my-favorite-supplies/my-favorite-supplies.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CoffeeComponent } from './coffee/coffee.component';

@NgModule({
  declarations: [
    AppComponent,
    HubComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    HomeLayoutComponent,
    HeaderComponent,
    FooterComponent,
    BlogLayoutComponent,
    BlogHeaderComponent,
    MyFavoriteSuppliesComponent,
    PagenotfoundComponent,
    CoffeeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BlogModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    FontAwesomeModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  providers: [AngularFireAuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
