<div class="container">
    <div class="row">
        <div class="col-lg-4 offset-lg-4 col">
            <p class="card-text">You can contact me by sending an email to info@amyswares.com</p>
            <a class="btn btn-primary" href="mailto:info@amyswares.com">Send Email</a>
            <p class="card-text mt-4">Or you can follow me at these links</p>
            <a class="btn btn-link" href="http://facebook.com/amyswares" target="_blank" style="color:black;"><fa-icon [icon]="faFacebookF"></fa-icon></a>
            <a class="btn btn-link" href="http://instagram.com/amybeth928" target="_blank"style="color:black;"><fa-icon [icon]="faInstagram"></fa-icon></a>
            <a class="btn btn-link" href="http://etsy.com/shop/amyswares" target="_blank"style="color:black;"><fa-icon [icon]="faEtsy"></fa-icon></a>
            <hr/>
        </div>
        <div class="col-lg-3 offset-lg-1 col-12">
            <div style="width:100%;">
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link" routerLink="/blog/home" style="border-radius:0px;">BLOG</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/hub" style="border-radius:0px;">LINKS</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/about"  style="border-radius:0px;">ABOUT</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/contact" style="border-radius:0px;">CONTACT</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>