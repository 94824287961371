import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SubComment, SubCommentForm } from 'src/app/models/subcomment.model';
import { SubcommentsService } from 'src/app/models/subcomments.service';
import { FormGroup, FormBuilder,Validators, FormControl } from '@angular/forms';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-subcomments',
  templateUrl: './subcomments.component.html',
  styleUrls: ['./subcomments.component.sass']
})
export class SubcommentsComponent implements OnInit {
  @Input() commentIdComments: string;
  subComments$:Observable<SubComment[]>;
  subCommentFormGroup: FormGroup;
  subCommentForm: SubCommentForm;
  blogId: string;
  constructor(public bcs: SubcommentsService, private formBuilder: FormBuilder, private afs: AngularFirestore) {
   }

  ngOnInit(): void {
    console.log("constructor commentId: " + this.commentIdComments);
    this.subComments$ = this.bcs.getSubComments(this.commentIdComments);
    this.subCommentFormGroup = new FormGroup({
      'author': new FormControl(null, [Validators.required]),
      'commentId':new FormControl(),
      'blogId': new FormControl(),
      'content': new FormControl(null, [Validators.required]),
      'commentDate': new FormControl(),
      'approved': new FormControl(),
      'isSubComment': new FormControl()
    });
    this.blogId = document.getElementById("hidden-blog-id").dataset.blogid;
  }
  async onSubmitSubComment(){
    if (this.subCommentFormGroup.valid){
      const result: SubCommentForm = Object.assign({}, this.subCommentFormGroup.value);
      result.approved = false;
      result.isSubComment = true;
      result.commentDate = new Date();
      result.commentId = this.commentIdComments;
      result.blogId = this.blogId;
      const res = await this.afs.collection('blogComments').add(result).then(() =>{
        document.getElementById("sub-comment-" + this.commentIdComments).classList.add("d-none");
        document.getElementById("sub-comment-alert-" + this.commentIdComments).classList.remove("d-none");
        document.getElementById("sub-comment-alert-" + this.commentIdComments).classList.add("alert-success");
        document.getElementById("sub-comment-alert-" + this.commentIdComments).innerHTML = "Your comment was saved and is awaiting approval.";
        this.subCommentFormGroup.reset();
      });
    } else {
      alert("Please fill in a name and comment.");
    }
  }


  showSubComment(){
    document.getElementById("sub-comment-"+this.commentIdComments).classList.remove("d-none");
  }

}
