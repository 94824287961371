<div class="container-fluid">
    <div class="row">
        <div class="col-lg-9 col-12">
            <div class="row no-gutters m-2">
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_1.jpg?alt=media&token=a5303a67-9178-4b49-87dc-bba4cc8d9b1c">
                </div>
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_8.jpg?alt=media&token=1a4160ff-7eb1-474d-9eb0-4cb176ae580b">
                </div>
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_5.jpg?alt=media&token=1ff7fd91-3e3d-425c-aced-71f42fd56cdf">
                </div>
            </div>
            <div class="row no-gutters m-2">
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_4.jpg?alt=media&token=d4e0dfe9-620b-4122-ba1c-d61a670a65a7">
                </div>
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_3.jpg?alt=media&token=5f9405a0-682c-41ec-9a7f-cc4165762d26">
                </div>
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_6.jpg?alt=media&token=b7c00720-1535-489e-b2a7-cf3bf99e0e98">
                </div>
            </div>
            <div class="row no-gutters m-2">
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_9.jpg?alt=media&token=a348a090-fd68-4c25-a2dd-19d7094a1e67">
                </div>
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_7.jpg?alt=media&token=d9d3474d-7df5-4b8a-81a9-75511b48e8c8">
                </div>
                <div class="col-4 pl-2">
                    <img class="img-fluid" alt="fp1" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/frontpage%2Fsm_2.jpg?alt=media&token=e4c089df-847c-4c28-ac93-60d920aa84e5">
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-12">
            <div style="width:100%;">
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link" routerLink="/blog/home" style="border-radius:0px;">BLOG</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/hub" style="border-radius:0px;">LINKS</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/about"  style="border-radius:0px;">ABOUT</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/contact" style="border-radius:0px;">CONTACT</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
