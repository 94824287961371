<div class="container">
    <div class="row mt-3">
        <div class="col-lg-4 col-12 mx-auto">
            <img  src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/aboutme%2Fsm_aboutme.jpg?alt=media&token=640f8b7d-d7d8-4af9-a51c-b7ae6d7db0ce" alt="Avatar" style="max-width:100%;;border-width: 3px;border-style:solid;border-color:white;">
        </div>
        <div class="col-lg-5 col-12 mt-5"  style="height:800px;">
            <p>Hi, I'm Amy. Thanks for visiting my page. I am a wife and a mama and a lover of all things crafty. I have an unyielding desire to create and most of the time lately that translates to handmade cards. What better way to share the love right? I make all sorts of things, and even sell some of them too! This site is a space for you to see what I'm up to. It's also a means for me to share some of my creations with you and how I went about making them. I hope that you find some inspiration here for your own crafty endeavors.</p>
            <p><strong>Creativity is contagious. Pass it On!<br/>-Albert Einstein</strong></p>
        </div>
        <div class="col-lg-3 col-12">
            <div style="width:100%;">
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link" routerLink="/blog/home" style="border-radius:0px;">BLOG</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/hub" style="border-radius:0px;">LINKS</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/about"  style="border-radius:0px;">ABOUT</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/contact" style="border-radius:0px;">CONTACT</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>