import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { SubComment } from 'src/app/models/subcomment.model';

@Injectable({
  providedIn: 'root'
})
export class SubcommentsService {
  getSubComments(commentId){
    let bcs$ = this.afs.collection<SubComment>('blogComments', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('commentId', '==', commentId);
      query = query.where('isSubComment','==',true);
      query = query.where('approved', '==', true);
      query.orderBy('commentDate','asc');
      return query;
    }).valueChanges({idField:'id'});
    return bcs$;
  }

  constructor(private afs: AngularFirestore) { }
}
