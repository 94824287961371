<div class="row">
    <div class="mx-auto" style="width:125px;">
        <img  src="../../assets/img/FB_IMG_1584296398183.jpg" alt="Avatar" style="max-width:100%;border-radius:50%;border-width: 3px;border-style:solid;border-color:white;">
    </div>
</div>
<!-- <div class="row mb-5">
    <div class="mx-auto" style="width:300px;">
        <img class="img-fluid" src="../../assets/img/aw_full.png" alt="full_logo" style="max-width:100%;height:auto;">
    </div>
</div> -->
<div class="row m-3">
</div>

<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.youtube.com/@amyswares" target="_blank" style="color:white;border-radius:0px;">YOUTUBE</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="http://www.instagram.com/amybeth928" target="_blank" style="color:white;border-radius:0px;">INSTAGRAM</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="/coffee/" target="_blank" style="color:white;border-radius:0px;">LEAVE AMY A THANK YOU TIP</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.amazon.com/shop/amyswares" target="_blank" style="color:white;border-radius:0px;">AMAZON STOREFRONT</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.shareasale.com/r.cfm?b=1504485&u=2425013&m=95422" target="_blank" style="color:white;border-radius:0px;">* A COLORFUL LIFE DESIGNS</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.shareasale.com/r.cfm?b=33086&u=2425013&m=7429" target="_blank" style="color:white;border-radius:0px;">* SCRAPBOOK.COM</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://shrsl.com/3tubx" target="_blank" style="color:white;border-radius:0px;">* SIMON SAYS STAMP</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.shareasale.com/r.cfm?b=1544529&u=2425013&m=97637" target="_blank" style="color:white;border-radius:0px;">* THE RABBIT HOLE DESIGNS</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.glassboardstudio.com/" target="_blank" style="color:white;border-radius:0px;">* GLASSBOARD STUDIO<br/>COUPON AMYSWARES20</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://shrsl.com/3uno1" target="_blank" style="color:white;border-radius:0px;">* A CHERRY ON TOP CRAFTS</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://shrsl.com/3uno4" target="_blank" style="color:white;border-radius:0px;">* LDRS CREATIVE</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.shareasale.com/r.cfm?b=1067072&u=2425013&m=74394" target="_blank" style="color:white;border-radius:0px;">* SCRAPBOOKPAL.COM</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.shareasale.com/r.cfm?b=855179&u=2425013&m=65361" target="_blank" style="color:white;border-radius:0px;">* HERO ARTS</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.shareasale.com/r.cfm?b=481500&u=2425013&m=47433" target="_blank" style="color:white;border-radius:0px;">* MY FAVORITE THINGS</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" routerLink="/blog/home" style="color:white;border-radius:0px;">BLOG</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="http://www.facebook.com/amyswares" target="_blank" style="color:white;border-radius:0px;">FACEBOOK</a>
    </div>
</div>
<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <a class="btn btn-lg btn-block btn-secondary" href="https://www.etsy.com/shop/AmysWares" target="_blank" style="color:white;border-radius:0px;">ETSY</a>
    </div>
</div>

<div class="row m-3 justify-content-center">
    <div class="col-md-4">
        <p class="text-center">* Affiliate Links</p>
    </div>
</div>
