import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HubComponent } from './hub/hub.component';
import { HomeComponent} from './home/home.component';
import { AngularFireAuthGuard, hasCustomClaim, customClaims, redirectUnauthorizedTo, canActivate } from '@angular/fire/auth-guard';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { CoffeeComponent } from './coffee/coffee.component';
import {HomeLayoutComponent} from './layout/home-layout/home-layout.component';
import {BlogLayoutComponent} from './layout/blog-layout/blog-layout.component';
import {MyFavoriteSuppliesComponent} from './my-favorite-supplies/my-favorite-supplies.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

// todo: this doesn't work
// const adminRedirect = () => pipe(customClaims, map(claim => hasCustomClaim('admin') ? ['admin/tools'] : ['user/login']));
const adminOnly = () => hasCustomClaim('admin');

const routes: Routes = [
  // blog layout routes go here (example from https://stackblitz.com/edit/angular-multi-layout-example?file=app%2Fapp.module.ts)
  {
    path: '',
    component: BlogLayoutComponent,
    children: [
        {path:'',component:HomeComponent},
        {path: 'hub',component: HubComponent},
        {path: 'about',component: AboutComponent},
        {path: 'coffee',component: CoffeeComponent},
        {path: 'my-favorite-supplies',component: MyFavoriteSuppliesComponent},
        {
          path: 'blog',
          loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
        },
        {path: 'contact',component: ContactComponent},
        // {
        //   path: 'admin',
        //   loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        //   canActivate: [AngularFireAuthGuard],
        //   data: {authGuardPipe: adminOnly}
        // },
        {
          path: 'admin',
          loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
          ...canActivate(adminOnly)
        },
        {
          path: 'user',
          loadChildren: () => import('./user/user.module').then(m => m.UserModule)
        },
        {
          path: '**', pathMatch: 'full',
            component: PagenotfoundComponent 
        },
      ]
  },

  // home layout routes go here
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
