import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BlogComponent} from './blog/blog.component';
import {HomeComponent} from './home/home.component';
import {SearchComponent} from './search/search.component';



const routes: Routes = [
  { path: 'entry/:id', component: BlogComponent},
  { path: 'home', component: HomeComponent},
  { path: 'search/:q', component: SearchComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogRoutingModule { }
