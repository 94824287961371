<div class="container">
    <div class="row">
        <div class="col-md-8 offset-md-2 col-6 mb-4 mt-2">
            <div class="mx-auto" style="width:100%;max-width:300px;">
                <a routerLink="/"><img class="img-fluid" src="../../assets/img/aw_full.png" alt="full_logo" style="max-width:100%;height:auto;"></a>
            </div>
        </div>
        <div class="col-md-2 col-6 d-flex justify-content-end">
            <a class="btn btn-link" href="http://facebook.com/amyswares" target="_blank" style="color:rgb(179, 179, 179);"><fa-icon [icon]="faFacebookF"></fa-icon></a>
            <a class="btn btn-link" href="http://instagram.com/amybeth928" target="_blank"style="color:rgb(179, 179, 179);"><fa-icon [icon]="faInstagram"></fa-icon></a>
            <a class="btn btn-link" href="http://etsy.com/shop/amyswares" target="_blank"style="color:rgb(179, 179, 179);"><fa-icon [icon]="faEtsy"></fa-icon></a>
        </div>
    </div>
</div>