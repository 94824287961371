<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 offset-lg-3 col-12">
            <div class="row">
                <div class="col-12">
                    <p>These are some of my favorite and most used items in my craft room. I am listing these products because I love them and use them frequently. This is by no means an exhaustive list of all that I use but is a highlight of some of my faves. These favorite items might change over time as I try new products but these are currently what I use. I have purchased these products on my own and I’m recommending them because I enjoy using them and I think that you will enjoy them as well.</p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <div class="h2 d-flex justify-content-center ">
                        PAPERS / CARDSTOCK
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2feh5">Neenah Paper 04556 Classic Crest Solar White 110lb Cardstock 8.5” x 11”</a>
                    <p>
                        (Be sure to note the number 04556 to be sure that you get the correct type of paper.) 
                        This is the card stock that I use 99% of the time for card bases as well as panels unless I am using a specialty paper for watercolor etc. Occasionally I will use the 80lb version of this cardstock for a panel but very seldom as I love the weight of the 110lb version. If you are going to only get one of the two, my vote is the 110lb.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://images-na.ssl-images-amazon.com/images/I/71LCM9O6K7L._AC_SL1500_.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a class="" href="https://amzn.to/2O7rZgz">Strathmore Bristol Smooth Paper</a>
                    <p>
                        In instances where I am going to use my Arteza real brush markers then I will use this paper. I usually have panels cut to A2 size ready to go for this purpose. This is not actually a watercolor paper however it can handle a decent amount of water. I find that the watercolor real brush markers blend much better on this smooth type of paper then they do even on watercolor paper. This is definitely a must have for your craft room if you have any watercolor type of brush pens/markers.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/blogimages%2Fbristol_strathmore.png?alt=media&token=565d4b74-a921-47d5-a14a-9892a59dc105">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/3ec1ott">Canson XL Watercolor Paper </a>
                    <p>
                        This has been my watercolor paper of choice mainly because of its affordability. I also keep this cut in A2 size panels and have them ready to go. In instances where I will be using a lot of water I will use this paper as my panel. I use it with my traditional watercolor paints from a pan or a tube or any instance where I will be using quite a bit of water as it can take it!
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://images-na.ssl-images-amazon.com/images/I/81Cec2wpjRL._AC_SL1500_.jpg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <div class="h2 d-flex justify-content-center">
                        INK PADS
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2d9km">Distress Oxide Ink Pads</a>
                    <p>
                        Guys, seriously I use these things SO MUCH! I use them for stamping, for ink blending, and even for watercoloring. They are fabulous! They are water reactive and they are a hybrid meaning they are part pigment and part dye inks. They are quicker drying than full pigment inks but have the layering opacity capabilities of a pigment so they are the best of both worlds. I didn’t start out this way as I was collecting them but I definitely have “full set syndrome” now with these and have collected every color that has come out so far.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/amys-wares.appspot.com/o/blogimages%2Fdistressed_oxide.png?alt=media&token=af9c670f-0a7f-453d-9634-84f4a1044e95">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2d9l5">Versamark Watermark Ink Pad</a>
                    <p>
                        This is my favorite heat embossing ink pad but it’s great for simple tone on tone watermarking looks as well. 
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://www.scrapbook.com/products/cache/SBC_ts-vm-000-001_17709_0.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2d9lm">Memento Tuxedo Black Dye Ink Pad</a>
                    <p>
                        This is one of my two go-tos for stamping an image when I know that I am going to be using an alcohol marker. This is a copic friendly black ink.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://www.scrapbook.com/products/cache/SBC_gb-tsmp900_0.jpg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <div class="h2 d-flex justify-content-center">
                        WATERCOLORING
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2d9jt">Arteza 96 Real Brush Pens</a>
                    <p>
                        I use these when I want a watercolor look with a little more control. Or if I want to color a stamped image but I’m too lazy to bust out my alcohol markers. You can get a huge gradient of color with just one marker and some water with these babies. Plus, I love the messy watercolor look that you can achieve with these. You can use them directly with a “pen to paper” technique or scribble some down on some acetate and use it more like an actual watercolor. My daughter and I use these all the time! These are very affordable compared to some other similar products out there. There is also a beautiful carrying case that you can purchase individually or with the set. There are smaller sets of this product available as well but as you know, I have a problem, “MUST HAVE ALL THE THINGS!” P.S. I have the awesome carrying case for this as well….I know, you are shocked.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://cdn.arteza.com/products/20/05/real-brush-pens-96-colors_8bKSyFmY_990x990.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2d9jy">Arteza Water Brush Pens - Set of 6</a>
                    <p>
                        I use these water brush pens in tandem with my Real Brush Pens (noted above). You can also use these with pan or tube watercolors as well. I love the different tip sizes that come in this set and the quality is fantastic for the price.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://cdn.arteza.com/products/20/05/water-brush-pens-set-of-6_tgfSCe-v_990x990.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/2ZaOYhm">Mijello Mission Gold Watercolor Palette Set 36 colors</a>
                    <p>
                        These are my favorite traditional watercolors so far. I’m still learning all the techniques and the best ways to use them but these are of an exceptional quality and have beautiful pigment and flow. Plus it comes with a palette!
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://images-na.ssl-images-amazon.com/images/I/711l%2BKWjzBL._AC_SL1500_.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2feh8">Kuretake Gansai Tambi Pigment Ink Watercolor Set 36 colors</a>
                    <p>
                        These are another watercolor set that I own. I absolutely love these as well but they seem to function very differently than the Mijello. These are extremely pigmented and almost seem more opaque. They don’t flow so much like a traditional watercolor but I love the richness and texture to the pigments. If you want the flow of a traditional watercolor paint then these might not be your cup of tea. If you want something that’s rich in pigment and has an opaque feel then these might be what you are looking for. 
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://images-na.ssl-images-amazon.com/images/I/718RKMvV0mL._AC_SL1000_.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/2VYoeyu">Silver Black Velvet Watercolor Brushes</a>
                    <p>
                        These are the watercolor brushes that I currently use. I own the sizes 4, 6, 8, 12, and the 1” flat brush. I feel that this is a good range in sizes for me though I might purchase a smaller size in the future to add to the collection. These are quality but still on the more affordable side in my opinion.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3 d-flex justify-content-center" >
                    <img class="img-fluid" style="height:200px;" src="https://images-na.ssl-images-amazon.com/images/I/71Vh9cQdwTL._AC_SL1500_.jpg">
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <div class="h2 d-flex justify-content-center">
                        TOOLS AND OTHER PRODUCTS
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2fehg">Spellbinders Platinum 6 Die Cutting Machine</a>
                    <p>
                        This is the platform die cutting machine that I use. 
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://images-na.ssl-images-amazon.com/images/I/71pw6Be%2BM%2BL._AC_SL1500_.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/2VZiGnh">Spellbinders Platinum Cutting plates</a>
                    <p>
                        When I need more plates these are the replacement
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2d9k9">Arteza Professional Colored Pencils - Set of 72</a>
                    <p>
                        These are the colored pencils that I currently use. The quality and price point really hit the mark for me. They have larger and smaller sets available as well. They also have a cute carrying case which of course I “had” to have.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://i5.walmartimages.com/asr/debd5748-98f0-4a86-9939-e9617976be36_1.ca0fbed12dd70f0691e8e30d5a1b973f.jpeg?odnHeight=450&odnWidth=450&odnBg=FFFFFF">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/2AJ1c7q">Gamsol</a>
                    <p>
                        If you want to blend out your colored pencil you can do so with gamsol. This is the one that I have. Be sure to be careful with this product and use it in a well ventilated area!
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/3ee25m7">Blending Stump Set</a>
                    <p>
                        This is what I use with the gamsol to blend with my colored pencils. It was a very complete set and I was excited to find it for such an affordable price.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://images-na.ssl-images-amazon.com/images/I/71L8F1-1nDL._AC_SL1500_.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/2CcVNWy">Scotch Indoor Mounting Foam Tape ¾ in</a>
                    <p>
                        Commonly referred to in the card making industry as the “big mama foam roll” this is what I often use to give my cards dimension by popping up certain elements of the card. It really does last a good while! 
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://images-na.ssl-images-amazon.com/images/I/81Ur1YfeGfL._AC_SL1500_.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/2O3sbxD">Uni Color Pencil Sharpener</a>
                    <p>
                        I don’t know about y’all but I am always riding the struggle bus when it comes to sharpening pencils. So, imagine my delight when I learned of this beauty from a youtube card video. I had to have it and it truly does not disappoint. It takes so little off of your pencil and doesn’t break the lead like so many others do. I’m telling ya, do yourself a favor-fave and get yourself one of these. 
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-3">
                    <img class="img-fluid" src="https://images-na.ssl-images-amazon.com/images/I/516N8VJ-0aL._AC_SL1108_.jpg">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2dgnp">Hero Arts White Detail Embossing Powder</a>
                    <p>
                        This is what I have in my craft room and what I use for my white heat embossing needs. It’s a fine detail type which I definitely want when I’m embossing words or those beautiful floral stamps that I’m always a sucker for. 
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://shrsl.com/2d9kz">Copic Markers</a>
                    <p>
                        I’m still collecting but I currently use the Copic Sketch markers for my alcohol marker coloring needs.
                    </p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <a href="https://amzn.to/2DdFyJd">Re-Sealable Celophane Cards</a>
                    <p>
                        This is what I use to store and protect all my handmade lovelies. These work for A2 size cards which is primarily all that I make. 
                    </p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <p>
                        *This list contains affiliate links. If you follow the link and end up making a purchase then I may receive a small commission at no additional cost to you. I am listing these items because I use them and love them and I think that you will too!
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-12">
            <div style="width:100%;">
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link" routerLink="/blog/home" style="border-radius:0px;">BLOG</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/hub" style="border-radius:0px;">LINKS</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/about"  style="border-radius:0px;">ABOUT</a>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-12">
                        <a class="btn btn-lg btn-block btn-link"  routerLink="/contact" style="border-radius:0px;">CONTACT</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
