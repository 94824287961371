<form id="blog-comment-form" [formGroup]="blogCommentFormGroup" (ngSubmit)="onSubmit()" novalidate>
    <div class="row">
        <div class="col-9">
            <input name="author" placeholder="Name" formControlName="author" type="text" class="form-control form-control-sm" id="author">
        </div>
        <div class="col-3">
            <button type="submit" class="btn btn-primary btn-sm">Submit</button>
        </div>
    </div>
    <div class="form-group">
        <label for="content">Comment</label>
        <textarea name="content" formControlName="content" type="text" class="form-control form-control-sm" id="content" rows="3"></textarea>
    </div>
</form>
<div id="blog-comment-alert" class="alert d-none">

</div>

<div class="m-2">
    <div *ngIf="blogComments$ | async; let blogComments; else loading">
        <div *ngFor="let blogComment of blogComments">
            <div class="row">
                <div class="col">
                    <p><strong>{{ blogComment.author }}</strong> on
                        {{ blogComment.commentDate.toDate().toLocaleString() }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p [innerHTML]=blogComment.content></p>
                </div>
            </div>
            <hr class="mt-0" />
            <app-subcomments [commentIdComments]=blogComment.id></app-subcomments>
        </div>
        <div *ngIf="blogComments.length === 0">No comments...</div>
    </div>
    <ng-template #loading>Loading comments&hellip;</ng-template>
</div>