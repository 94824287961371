<div class="d-flex justify-content-end m-0">
    <button class="btn btn-link p-0" (click)="showSubComment()">Reply</button>
</div>
<form class="d-none" attr.id="{{ 'sub-comment-' + commentIdComments }}" [formGroup]="subCommentFormGroup" (ngSubmit)="onSubmitSubComment()" novalidate>
    <div class="row">
        <div class="col-9">
            <input name="author" placeholder="Name" formControlName="author" type="text" class="form-control form-control-sm" id="author">
        </div>
        <div class="col-3">
            <button type="submit" class="btn btn-primary btn-sm">Submit</button>
        </div>
    </div>
    <div class="form-group">
        <label for="content">Comment</label>
        <textarea name="content" formControlName="content" type="text" class="form-control form-control-sm" id="content" rows="3"></textarea>
    </div>
</form>
<div attr.id="{{ 'sub-comment-alert-' + commentIdComments }}" class="alert d-none">

</div>
<div class="m-2 border-left pl-2">
    <div *ngIf="subComments$ | async; let subComments; else loading">
        <div *ngFor="let subComment of subComments">
            <div class="row">
                <div class="col">
                    <p><strong>{{ subComment.author }}</strong> on {{ subComment.commentDate.toDate().toLocaleString() }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p [innerHTML]=subComment.content></p>
                </div>
            </div>
            <hr class="mt-0" />
        </div>
    </div>
    <ng-template #loading>Loading comments&hellip;</ng-template>
</div>