import { Component, OnInit } from '@angular/core';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import {faEtsy} from '@fortawesome/free-brands-svg-icons';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {
  faFacebookF = faFacebookF;
  faEtsy = faEtsy;
  faInstagram = faInstagram;
  constructor() { }
  ngOnInit(): void {
  }
}
