import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';
import { Blog } from 'src/app/models/blog.model';
@Component({
  selector: 'app-featured-list',
  templateUrl: './featured-list.component.html',
  styleUrls: ['./featured-list.component.sass']
})
export class FeaturedListComponent implements OnInit {
  arrBlogs: Blog[];
  items: number;
  pages: number;
  pagesize: number = 3;

  featuredRedirect(blogId){
    this.router.navigateByUrl('blog/entry/' + blogId);
  }

  constructor(private afs: AngularFirestore, private router:Router) {
  }


  ngOnInit(): void {
    this.afs.collection<Blog>('blogs', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      let d = new Date();
      query = query.where('postDate','<=',d);
      query = query.where('featured', '==', true);
      query = query.orderBy('postDate','desc');
      query = query.limit(6)
      return query;
    }).valueChanges({idField:'id'}).subscribe(blogs => {
      if (blogs){
        this.arrBlogs = blogs;
        this.items = this.arrBlogs.length;
        this.pages = Math.floor(this.items/this.pagesize);
        if (this.items%this.pagesize != 0){
          this.pages = this.pages + 1;
        }
        let p = [].constructor(this.pages);
      }
    });
  }

}
