import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import { trigger, transition, animate, style } from '@angular/animations'
import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import {faEtsy} from '@fortawesome/free-brands-svg-icons';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faUserCircle} from '@fortawesome/free-solid-svg-icons';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-blog-header',
  templateUrl: './blog-header.component.html',
  styleUrls: ['./blog-header.component.sass'],
  animations:[
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class BlogHeaderComponent implements OnInit {
  visible = false;
  faSearch = faSearch;
  faFacebookF = faFacebookF;
  faEtsy = faEtsy;
  faInstagram = faInstagram;
  faUserCircle = faUserCircle;

  trySearch(event:any){
    if (event.keyCode == 13) {
      let aString = event.target.value.replace(" ", "%20");
      console.log(aString);
      this.router.navigateByUrl('blog/search/' + aString);
      // this.router.navigate(['blog/search/'], {queryParams:{q:aString}});
    }
  }
  constructor(private router:Router, public auth: AngularFireAuth) {
  }

  ngOnInit(): void {
  }
  login(){
    this.router.navigateByUrl('user/login');
  }
  logout(){
    this.auth.signOut()
  }

}
