import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Blog} from 'src/app/models/blog.model';

@Component({
  selector: 'app-front-page-list',
  templateUrl: './front-page-list.component.html',
  styleUrls: ['./front-page-list.component.sass']
})
export class FrontPageListComponent implements OnInit {
  blogs$: Observable<Blog[]>;

  goToBlog(event,blogId){
    this.router.navigateByUrl('blog/entry/' + blogId);
  }
  constructor(afs: AngularFirestore, private router:Router) { 
    this.blogs$ = afs.collection<Blog>('blogs', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      let d = new Date();
      query = query.where('postDate','<=',d);
      query = query.where('frontPage','==',true);
      query = query.where('published','==',true);
      query = query.orderBy('postDate','desc');
      query = query.limit(1);
      return query;
    }).valueChanges({idField:'id'});
  }

  ngOnInit(): void {
  }

}
