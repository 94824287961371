import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {Router} from '@angular/router';
import {Blog} from 'src/app/models/blog.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  startTimeFilter$: BehaviorSubject<Date|null>;
  endTimeFilter$: BehaviorSubject<Date|null>;
  blogs$: Observable<Blog[]>;

  goToBlog(event,blogId){
    this.router.navigateByUrl('blog/entry/' + blogId);
  }
  goToOlderPosts(startTime: any|null){
    console.log(startTime);
    this.endTimeFilter$.next(null);
    this.startTimeFilter$.next(startTime);
  }
  goToNewerPosts(endTime: any|null){
    console.log(endTime);
    this.startTimeFilter$.next(null);
    this.endTimeFilter$.next(endTime);
  }
  constructor(afs: AngularFirestore, private router:Router) {
    this.startTimeFilter$ = new BehaviorSubject(null);
    this.endTimeFilter$ = new BehaviorSubject(null);
    this.blogs$ = combineLatest(
      this.startTimeFilter$,
      this.endTimeFilter$
    ).pipe(
      switchMap(([startTime, endTime]) => 
        afs.collection<Blog>('blogs', ref => {
          let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          let d = new Date();
          query = query.where('postDate','<=',d);
          query = query.where('published','==',true);
          query = query.orderBy('postDate','desc');
          if (startTime) { query = query.startAfter(startTime)};
          if (endTime) { query = query.endAt(endTime)};
          query = query.limit(5);
          return query;
        }).valueChanges({idField:'id'})
      )
    );
  }

  ngOnInit(): void {
  }

}
