<div *ngIf="blogs$ | async; let blogs; else loading">
    <div class="row">
        <div class="col">
            <div *ngFor="let blog of blogs">
                <div class="card border-0 mb-4" style="cursor:pointer;" (click)="goToBlog($event,blog.id)">
                    <img [src]="blog.coverPhoto" class="card-img-top" alt="blog.title">
                    <div class="card-body">
                        <h5 class="card-title" [innerHTML]="blog.title"></h5>
                        <p class="card-text" [innerHTML]="blog.introduction"></p>
                    </div>
                </div>
            </div>
            <div *ngIf="blogs.length === 0">No blog posts to read!</div>        
        </div>
    </div>
</div>
<ng-template #loading>Loading...</ng-template>
