<nav class="navbar navbar-light" sylte="max-height:38px;">
    <div class="form-inline">
      <div class="input-group">
        <input *ngIf="visible" (focusout)="visible=!visible" (keypress)="trySearch($event)" class="form-control" type="search" placeholder="Search" aria-label="Search">
        <a *ngIf="!visible" class="btn btn-outline-primary" (click)="visible = !visible" type="button"><fa-icon [icon]="faSearch"></fa-icon></a>
      </div>
    </div>
    <div class="col-md-2 col-3 d-flex justify-content-end">
      <a class="btn btn-link pl-2 pr-2" href="http://facebook.com/amyswares" target="_blank" style="color:rgb(179, 179, 179);"><fa-icon [icon]="faFacebookF"></fa-icon></a>
      <a class="btn btn-link pl-2 pr-2" href="http://instagram.com/amybeth928" target="_blank"style="color:rgb(179, 179, 179);"><fa-icon [icon]="faInstagram"></fa-icon></a>
      <a class="btn btn-link pl-2 pr-2" href="http://etsy.com/shop/amyswares" target="_blank"style="color:rgb(179, 179, 179);"><fa-icon [icon]="faEtsy"></fa-icon></a>
      <!-- <div class="btn-group dropleft"  *ngIf="auth.user | async as user; else showLogin">
          <button class="btn btn-link dropdown-toggle" type="button" id="dropdownUserMenuBtn" data-toggle="dropdown" aria-haspopup="true"aria-expanded="false" style="color:rgb(179, 179, 179);">
              <span><fa-icon [icon]="faUserCircle" ></fa-icon></span>
          </button>
          <div class="dropdown-menu" style="width:250px;" aria-labelledby="dropdownUserMenuBtn">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  Hello, {{ user.displayName }}!
                </div>
              </div>
              <p>{{ user.email }}</p>
              <a class="dropdown-item" routerLink="/admin/tools">Admin</a>
              <a class="dropdown-item" routerLink="/user/profile">View Profile</a>
              <a class="dropdown-item" (click)="logout()" href="#">Logout</a>
              </div>
          </div>
      </div>
      <ng-template #showLogin>
        <button class="btn btn-link" (click)="login()">Login</button>
      </ng-template> -->
  </div>
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon d-flex align-items-center" ></span>
    </button> 
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class=" d-flex justify-content-end">
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" routerLink="">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Design Team Projects</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">My Favorite Supplies</a>
                </li>
            </ul>
        </div>
    </div> -->
  </nav>
  <div class="row mb-3">
      <div class="col d-flex justify-content-center">
        <a routerLink="/"><img class="img-fluid" src="../../assets/img/aw_full.png" alt="full_logo" style="max-width:100%;max-height:100px;"></a>
      </div>
  </div>